import React from "react"
import { getUser } from "../../services/auth"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"

const Profile = () => (
  <>
  <Container>
	<Type type="h2">Your profile</Type>
	<ul>
	  <li>Name: {getUser().name}</li>
	  <li>E-mail: {getUser().email}</li>
	</ul>
  </Container>
  </>
)

export default Profile