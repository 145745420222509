import React from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../../services/auth"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"


class Login extends React.Component {
  state = {
	username: ``,
	password: ``,
  }

  handleUpdate = event => {
	this.setState({
	  [event.target.name]: event.target.value,
	})
  }

  handleSubmit = event => {
	event.preventDefault()
	handleLogin(this.state)
  }

  render() {
	if (isLoggedIn()) {
	  navigate(`/app/profile`)
	}

	return (
	  <>
	  <Container>
		<Type type="h2">Log in</Type>
		<form
		  method="post"
		  onSubmit={event => {
			this.handleSubmit(event)
			navigate(`/app/profile`)
		  }}
		>
		  <label>
			Username
			<input className="p-2 border rounded-md block mb-8" type="text" name="username" onChange={this.handleUpdate} />
		  </label>
		  <label>
			Password
			<input
			className="p-2 border rounded-md block"
			  type="password"
			  name="password"
			  onChange={this.handleUpdate}
			/>
		  </label>
		  <input className="rounded-md bg-green text-white font-bold py-2 px-6 mt-8" type="submit" value="Log In" />
		</form>
	  </Container>
	  </>
	)
  }
}

export default Login