import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout/Layout"
import Profile from "../components/app/profile"
import Login from "../components/app/login"
import PrivateRoute from "../components/app/privateRoute"


const App = () => (
  <Layout>
	<Router>
	  <PrivateRoute path="/app/profile" component={Profile} />
	  <Login path="/app/login" />
	</Router>
  </Layout>
)

export default App